<template>
  <div class="home">
    <div class="banner-style">
      <img src="../assets/images/home1/home-banner2.png" class="bimg" alt="" srcset="" width="100%" />
    </div>
    <!-- 协会介绍 -->
    <div class="introduce">
      <div class="introduce-content">
        <div class="introduce-left">
          <div class="association-title">联盟介绍</div>
          <div class="subheading">{{xhjs.name}}</div>
          <div class="contnet-text">
           {{xhjs.description}}
          </div>
          <div class="btn-style" @click="goIntroduce">查看详情>></div>
        </div>
        <div class="introduce-right">
			 
          <img  :src="xhjs.img" alt="" srcset="" width="100%" />
        </div>
      </div>
    </div>
    <!-- 政治法规 -->
    <div class="political">
      <div class="political-content">
        <div class="title sbtitle">政治法规</div>
        <div class="content">
          <div class="content-left">
            <div>
              <img
                :src="zzfg.img"
                alt=""
                srcset=""
                width="100%"
                height="280px"
              />
            </div>
            <div class="shadow">
              <div class="subheading">{{zzfg.name}}</div>
              <div class="contnet-text">
               {{zzfg.description}}
              </div>
              <div class="time-detail">
                <div class="timetext">{{zzfg.time}}</div>
                <div class="moretext" @click="goPolitical(zzfg.id)">查看详情>></div>
              </div>
            </div>
          </div>
          <div class="content-right">
            <div v-for="(item, index) in politicaList" class="citemone" :key="index" :limit="4" @click="goPolitical(item.id)">
              <div class="item-box">
                <div class="item-left">
                <div class="number-style">{{ item.cjsj.split('-').pop() }}</div>
                  <div class="time">{{ item.cjsj.split('-').slice(0, 2).join('-') }}</div>
                </div>
                <div class="item-right">
                  <p class="title-style">{{ item.bt }}</p>
                  <p class="content-text">{{ item.nr }}</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业风采 -->
    <div class="firm">
      <div class="firm-content">
        <div class="title sbtitle">联盟风采</div>
        <div class="content">
          <div class="item-box" v-for="(item, index) in firmList" :key="index">
			
          <img :src="imgurl+item.img" alt="" width="100%" height="200" />
            <div class="name">{{ item.gsmc }}</div>
            <div class="content">{{ item.nr }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 专家介绍 -->
    <div class="expert">
      <div class="expert-contnet">
        <div class="title">专家介绍</div>
        <div class="content">
          <div class="eitem-box" v-for="(item, index) in expertList" :key="index"  >
           <img :src="imgurl+item.img"  width="100%" height="100%  " />
            <div class="overlay" >
              <p class="oname">{{ item.xm }}</p>
              <p class="open">{{ item.zw }}</p>
              <p class="odesc">{{ item.jj }}</p>
              <p class="more" @click="goDetail(item.id)">>查看更多>></p>
            </div>
            <div class="non-hover-text" >
              <div>{{ item.xm }}</div>
              <div class="position">{{ item.zw }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { setTimeout } from "timers";
import request from "../utils/request.js";
import { apiUrl ,fileUrl} from '@/config'
//formMake/view
export default {
  name: "home",
  data() {
    return {
		imgurl:apiUrl,
		fileUrl:fileUrl,
		xhjs:{	
		},
		zzfg:{
		},
      politicaList: [
        // {
        //   number: "09",
        //   time: "2019-09",
        //   title: "关于举办2019年“创青春”全国大学生创业大赛的通知",
        //   content:
        //     "各有关单位：为贯彻落实《国务院办公厅关于深化高等学校创新创业教育改革的意见》和《教育部办公厅关于在本科教育中加强创新创业教育改革工作的通知》文件精神，推进“互联网+教育”建设，促进大学生创新创业活动，提升大学生创新创业能力，经研究，决定举办2019年“创青春”全国大学生创业大赛。现将有关事项通知如下：一、大赛简介2019年“创青春”全国大学生创业大赛是经教育部批准，由共青团中央、中国科协、教育部、全国学联、中国发明协会、全国青年志愿者协会、中国教育学会、中国Association for Science and Technology Education、中国教育学会教育信息化专委会、中国教育学会教育大数据专业委员会、中国教育学会教育大数据专业委员会、中国教育",
        // }
      ],
      firmList: [
    //     {
    //       img: require("../assets/images/home1/qy1.png"),
    //       name: "北京中科美络科技有限公司",
    //       content:
    //         "各有关单位：为贯彻落实《国务院办公厅关于深化高等学校创新创业教育改革的意见》和《教育部办公厅关于在本科教育中加强创新创业教育改革工作的通知》文件精神，推进“互联网+教育”建设，促进大学生创新创业活动，提升大学生创新创业能力，经研究，决定举办2019年“创青春”全国大学生创业大赛。现将有关事项通知如下：一、大赛简介2019年“创青春”全国大学生创业大赛是经教育部批准，由共青团中央、中国科协、教育部、全国学联、中国发明协会、全国青年志愿者协会、中国教育学会、中国Association for Science and Technology Education、中国教育学会教育信息化专委会、中国教育学会教育大数据专业委员会、中国教育学会教育大数据专业委员会、中国教育",
    //     }
    
      ],
      expertList: [
        // {
        //   img: require("../assets/images/home1/zjjs1.png"),
        //   name: "陈xx",
        //   position: "xx总裁",
        //   description:
        //     "各有关单位：为贯彻落实《国务院办公厅关于深化高等学校创新创业教育改革的意见》和《教育部办公厅关于在本科教育中加强创新创业教育改革工作的通知》文件精神，推进“互联网+教育”建设，促进大学生创新创业活动，提升大学生创新创业能力，经研究，决定举办2019年“创青春”全国大学生创业大赛。现将有关事项通知如下：一、大赛简介2019年“创青春”全国大学生创业大赛是经教育部批准，由共青团中央、中国科协、教育部、全国学联、中国发明协会、全国青年志愿者协会、中国教育学会、中国Association for Science and Technology Education、中国教育学会教育信息化专委会、中国教育学会教育大数据专业委员会、中国教育学会教育大数据专业委员会、中国教育",
        //   isHovered: false,
        // }
      ],
    };
  },
  watch: {},
  methods: {
    goIntroduce(){
      this.$router.push({name:'association'})
    },
    /*政治法规详情*/ 
    goPolitical(id){
       this.$router.push({path:"politicalDeatil/595/"+id})
    },
	goDetail(item){
	   
		  this.$router.push({path:"expertdetails/597/"+item.id})
	},
	/**协会介绍**/
	getXhxx(){
		var that=this
		var queryId=598
		var dataId=1
		request.get('/formMake/view/'+queryId+'/'+dataId).then(res =>{
			if(res.code==0){
				const {data}=res.data
				that.xhjs.name=data.editMData.xhmc
				that.xhjs.description=data.editMData.xhjj
				if (data && data.editMData && data.editMData.tp && data.editMData.tp.length > 0) {
				    // 获取第一个图片的URL  
				    var imageUrl = data.editMData.tp[0].compressUrl;  
				      
				    // 将URL赋值给that.zzfg.img  
				    that.xhjs.img = this.fileUrl+imageUrl;  
				} else {  
					that.xhjs.img=require("../assets/images/home1/xhjs1.png")//../assets/images/home1/xhjs1.png
				    console.error("No image URL found in the data.");  
				}
			}
		})
	},
	/**政治法规列表**/
	getZzfg(){
		var that=this
		var parm=
			{
			    "groupval": "",
			    "pageSize": 10,
			    "pageNum": 1,
			    "orderByColumn": "id",
			    "isAsc": "desc",
			    "formId": 824,
			    "xssx": "",
			    "queryId": "595",
			    "queryStr": "",
			    "isId": 0
			}
				
		request.post('/spms/previewQuery/list',parm).then(res =>{
			if(res.code==200){
				const {data}=res
				that.politicaList=data.rows;
				that.zzfg.id=that.politicaList[0].id
				that.zzfg.name=that.politicaList[0].bt
				that.zzfg.img=that.politicaList[0].tpDepotCompressUrl?that.imgurl+that.politicaList[0].tpDepotCompressUrl[0]:require('../assets/images/home1/zcfg1.jpg')
				that.zzfg.time=that.politicaList[0].cjsj
				that.zzfg.description=that.politicaList[0].nr
				
			}
		})
	},
	/**政治法规详情**/
	getZzfgxq(){
		var that=this
		var queryId=595
		var dataId=7
		request.get('/formMake/view/'+queryId+'/'+dataId).then(res =>{
			if(res.code==200){
				const {data}=res
				that.zzfg.name=data.editMData.bt
				that.zzfg.time=data.editMData.cjsj
				that.zzfg.description=data.editMData.nr
				if (data && data.editMData && data.editMData.tp && data.editMData.tp.length > 0) {  
				    // 获取第一个图片的URL  
				    var imageUrl = data.editMData.tp[0].url;  
				      
				    // 将URL赋值给that.zzfg.img  
				    that.zzfg.img = imageUrl;  
				} else {  
				    console.error("No image URL found in the data.");  
				}
				
			}
		})
	

	},
	
	/**企业风采列表**/
	getQyfc(){
		var that=this
		var parm=
		{
		    "groupval": "",
		    "pageSize": 10,
		    "pageNum": 1,
		    "orderByColumn": "id",
		    "isAsc": "desc",
		    "formId": 825,
		    "xssx": "",
		    "queryId": "596",
		    "queryStr": "",
		    "isId": 0
		}
				
		request.post('/spms/previewQuery/list',parm).then(res =>{
			if(res.code==200){
				const {data}=res
				
				data.rows.forEach(item =>{
					if(item.tpDepotCompressUrl && item.tpDepotCompressUrl.length>0){
						item.img=item.tpDepotCompressUrl[0]
					}
					
				})
				that.firmList=data.rows;
				
			}
		})
	},
	/**专家介绍列表**/
	getZjjs(){
		var that=this
		var parm=
{
    "groupval": "",
    "pageSize": 10,
    "pageNum": 1,
    "orderByColumn": "id",
    "isAsc": "desc",
    "formId": 826,
    "xssx": "xm,zw",
    "queryId": "597",
    "queryStr": "",
    "isId": 0
}
				
		request.post('/spms/previewQuery/list',parm).then(res =>{
			if(res.code==200){
				const {data}=res
				data.rows.forEach(item =>{
					if(item.txDepotCompressUrl && item.txDepotCompressUrl.length>0){
						item.img=item.txDepotCompressUrl[0]
					}
					
				})
				that.expertList=data.rows;
				
			}
		})
	}
  },
  mounted() {
	  var that=this
	    that.getXhxx()
		that.getZzfg()
	 //  that.getZzfgxq()
	   that.getQyfc()
	    that.getZjjs()
	
  },
};
</script>
<style lang="scss" scoped>
.sbtitle{
  margin-top:60px !important;
}
.home {
  .banner-style {
    width: 100%;
    height:300px;
    .bimg{
      width: 100%;
      height:100%;
    }

  }

  // 协会介绍
  .introduce {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    padding-bottom: 100px;

    .introduce-content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .introduce-left {
        width: 40%;
        .association-title {
          font-size: 26px;
          color: #154ba4;
          border-bottom: 5px solid #f8b31a;
          font-weight: bold;
          line-height: 55px;
          width: 110px;
        }
        // 副标题
        .subheading {
          margin-top: 30px;
          font-size: 18px;
          font-weight: bold;
        }
        // 内容
        .contnet-text {
          margin-top: 30px;
          // padding-right: 55px;
          font-size: 15px;
          font-weight: bold;
          line-height: 30px;

        }
        // 按钮
        .btn-style {
          margin-top: 80px;
          border-radius: 30px;
          background-color: #1367c5;
          color: white;
          line-height: 50px;
          width: 140px;
          display: flex;
          justify-content: center;
          align-items: center;
		  cursor: pointer;
        }
      }
      .introduce-right{
        width: 40%;
      }

    }
  }
  // 政治法规
  .political {
    background: url(../assets/images/home1/bj1.png);
    background-size: cover; /* 设置背景图片为等比例缩放，并完整显示 */
    background-position: center;
    background-repeat: no-repeat;
    // height: 660px;
    .political-content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      padding-top: 15px;
      .title {
        color: white;
        font-size: 28px;
        line-height: 60px;
        border-bottom: 5px solid #f8b31a;
        width: 120px;
        font-weight: bold;
        margin: 0 auto;
        // padding-top: 30px;
      }
      .content {
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 510px;
        .content-left {
          width: 490px;
          height: 510px;
          .shadow {
            box-shadow: 1px 1px 1px #ebeaea;
            padding: 0px 30px;
            height: 230px;
            .subheading {
              font-size: 18px;
              font-weight: bold;
              // line-height: 80px;
            }
            .contnet-text {
              line-height: 30px;
              font-size: 14px;
              color: #666666;
              overflow: hidden; //超出文本隐藏
              text-overflow: ellipsis; ///超出部分省略号显示
              display: -webkit-box; //弹性盒模型
              -webkit-box-orient: vertical; //上下垂直
              -webkit-line-clamp: 3;
            }
            .time-detail {
              display: flex;
              justify-content: space-between;
              align-items: center;
              line-height: 50px;
              color: #bbbbbb;
            }
			.moretext{
				cursor: pointer;
			}
			
          }
        }
        .content-right {
          width: 680px;
          background-color: white;
          box-shadow: 2px 2px 3px #ebeaea;
          padding: 10px;
          height: 510px;
          overflow: hidden;
          cursor: pointer;
         
          .citemone{
            border-bottom:1px solid #e6e6e6;
            &:hover{
              .number-style{
                color: #005aab;
              }
              .time{
                color: #005aab;
              }
              .title-style{
                color: #005aab;
              }
           }
          }
          .citemone:last-child{
            border:none;
          }
          .item-box {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            height:167px;
            .item-left {
              padding: 10px;
              flex-shrink: 0;
              width:120px;
              height:120px;
              display:flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #e6e6e6;
              .number-style {
                font-size: 24px;
               
                font-weight: bold;
              }
              .time {
                font-size: 14px;  
                // color: #005aab;
                font-weight: bold;
              }
            }
            .item-right {
              
              padding:0 0 0 30px;
              .title-style {
                font-size: 18px;
                font-weight: bold;
               
                line-height: 20px;
              }
              .content-text {
                margin-top:20px;
                width: 100%;
                height: 60px;
                line-height: 30px;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #666666;
                text-overflow: ellipsis; ///超出部分省略号显示
                display: -webkit-box; //弹性盒模型
                -webkit-box-orient: vertical; //上下垂直
                -webkit-line-clamp: 2; //自定义行数
              }
            }
          }
        }
      }
    }
  }
  // 企业风采
  .firm {
    .firm-content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      margin-bottom: 110px;
      .title {
        font-size: 28px;
        line-height: 60px;
        border-bottom: 5px solid #f8b31a;
        width: 120px;
        font-weight: bold;
        margin: 0 auto;
        // padding-top: 30px;
        margin-bottom: 50px;
      }
      .content {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .item-box {
          width: 30%;
          // height: 300px;
          padding-bottom:10px;
          margin-top: 10px;
          overflow: hidden;
          line-height: 35px;
          overflow: hidden; //超出文本隐藏
          text-overflow: ellipsis; ///超出部分省略号显示
          display: -webkit-box; //弹性盒模型
          -webkit-box-orient: vertical; //上下垂直
          -webkit-line-clamp: 2; //自定义行数
          .name {
            font-size: 18px;
            color: #333333;
            font-weight: bold;
            margin-top:30px;
          }
          .content {
            font-size: 14px;
            color: #707070;
            margin-top:15px;
            line-height: 26px;
            height:50px;
            overflow: hidden; //超出文本隐藏
          text-overflow: ellipsis; ///超出部分省略号显示
          display: -webkit-box; //弹性盒模型
          -webkit-box-orient: vertical; //上下垂直
          -webkit-line-clamp: 2; //自定义行数
          }
        }
      }
    }
  }

  // 专家介绍
  .expert {
    width: 100%;
    background-color: #f5f6f9;
    padding: 50px 0px 60px 0px;
    .expert-contnet {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .title {
        font-size: 28px;
        line-height: 60px;
        border-bottom: 5px solid #f8b31a;
        width: 120px;
        font-weight: bold;
        margin: 0 auto;
        margin-bottom: 50px;
      }
      .content {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .eitem-box {
            width: 290px;
            height: 360px;
          position: relative;
          display: inline-block;
          overflow: hidden;
          cursor: pointer;
          &:hover{
            .overlay{
              display: block;
            }
            .non-hover-text{
              display: none;
            }
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            
            color: white;
            font-size: 18px;
            display: none;
            padding:20px;
            .odesc{
              height:200px;
              overflow: hidden;
            }
          }
          .overlay p {
              text-align: left;
          }
          .non-hover-text {
            
            position: absolute;
            bottom: 0;
            left: 0;
            color: white;
            padding: 15px;
            font-size: 18px;
            div{
              margin-top:3px;
            }
          }
        }

      }
    }
  }


}

</style>